import * as React from 'react'

import { AuthLogin } from 'shared/pages'

import Layout from '../../layouts/landing'

const Login = () => (
  <Layout>
    <AuthLogin />
  </Layout>
)

export default Login
